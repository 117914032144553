import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>Alles is mogelijk</h2>
      <p>
        Ben je opzoek naar een speciale taart voor een speciale gelegenheid dan kan BySoes een unieke taart
        voor u maken. Een custom made cake voor elke gelegenheid, of het nou een verjaardag is of een
        bruiloft. Alles is mogelijk.
      </p>

      <h3><a href="/voorwaarden">Algemene Voorwaarden</a></h3>
    </section>
    <section>
      <h2>Contact</h2>
      <dl className="alt">
        <dt>Address</dt>
        <dd>Amsterdam</dd>
        <dt>Phone</dt>
        <dd>0624225959</dd>
        <dt>Email</dt>
        <dd>
          <a href="https://bysoes.com">bysoes@outlook.com</a>
        </dd>
      </dl>
      <ul className="icons">
        <li>
          <a href="https://www.facebook.com/BySoes-511822136010895" className="icon fa-facebook alt">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/by_soes/" className="icon fa-instagram alt">
            <span className="label">Instagram</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">
      &copy; All rights reserved. <a href="https://bysoes.com">BySoes</a>.
    </p>
  </footer>
)

export default Footer
