import React from 'react'

import logo from '../assets/images/logo.png';

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="logo" width="150" /></span>
        <h1>BySoes</h1>
        <p>Unieke taarten en meer</p>
    </header>
)

export default Header
