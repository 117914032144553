import React from 'react'
import Layout from '../components/layout'
import Header from '../components/Header'

class Voorwaarden extends React.Component {
  render() {
    return (
      <Layout>
        <Header />

        <div id="main">
          <section id="content" className="main">

            <header className="major">
              <h2>Leverings- en betalingsvoorwaarden</h2>

              <h3 className="align-left">Bestellen</h3>
              <ul>
                <li>
                  Bestellingen kunnen 24 uur per dag en 7 dagen in de week
                </li>
                <li>
                  Liever 3-4 weken van te voren bestellen
                </li>
                <li>
                  Bruidstaarten moeten minimaal 2 maanden van te voren worden besteld
                </li>
                <li>
                  BySoes doet haar uiterste best om zo spoedig mogelijk te reageren
                </li>
                <li>
                  Voor spoedbestellingen kunt altijd telefonisch contact opnemen. Hiervoor gelden wel andere
                  betalings- en prijsvoorwaarden
                </li>
              </ul>

              <h3 className="align-left">Betalen</h3>
              <ul>
                <li>
                  Na ons persoonlijk contact stuurt bysoes een bevestigingsmail/app met alle gemaakte afspraken
                  erin. Daarna ontvangt u een betaallink.
                </li>
                <li>
                  U bestelling is pas 100% definitief wanneer BySoes een aanbetaling heeft van tenminste 50%
                  of het gehele orderbedrag heeft ontvangen. Het resterende bedrag dient 2 dagen voor het
                  ophaal of lever dag binnen te zijn.
                </li>
              </ul>

              <h3 className="align-left">Annulering</h3>
              <ul>
                <li>
                  Tot 7 dagen voor de leverdatum wordt uw aanbetaling minus €15 reserveringskosten teruggestort.
                  Eventuele gemaakte kosten, zal BySoes ook in rekening brengen.
                </li>
              </ul>

              <h3 className="align-left">Onmacht</h3>
              <ul>
                <li>
                  BySoes is een eenmanszaak en behoudt zich het recht voor bij ziekte, letsel of zwaarwegende (prive)
                  omstandigheden de opdracht te annuleren of uit te stellen. Uiteraard zal BySoes eerst een passende
                  oplossing proberen te zoeken.
                </li>
              </ul>

              <h3 className="align-left">Vervoeren</h3>
              <ul>
                <li>
                  Bij het ophalen van de taart is het vervoer voor eigen risicio. BySoes zorgt ervoor dat u
                  bestelling stabiel en goed verpakt is.
                </li>
                <li>
                  Tegen vergoeding kan uw bestelling ook bezorgd worden echter is BySoes niet aansprakelijk
                  voor schade tijdens of als gevolg van transport.
                </li>
              </ul>

              <h3 className="align-left">Klachten</h3>
              <ul>
                <li>
                  Indien u klachten heeft over het bestelde product dient u dit binnen 24 uur kenbaar
                  te maken en een stuk van het product te bewaren. BySoes kan u klacht beoordelen en
                  aan een passende oplossing werken. Klachten die later dan 24 uur na ophalen/bezorgen
                  worden ingediend kunnen niet in behandeling worden genomen.
                </li>
              </ul>

              <h3 className="align-left">Overige</h3>
              <ul>
                <li>
                  BySoes heeft het recht het ontwerp aan te passen op het laatste moment indien dit noodzakelijk is, een
                  beter eindresultaat blijkt te geven of in het geval dat materialen/ingredienten niet op tijd geleverd
                  zijn.
                </li>
                <li>
                  Taarten worden nooit exact nagemaakt van voorbeelden, kleuren kunnen afwijken i.v.m.
                  voedingskleurstoffen die bijv. niet in nl zijn toegestaan. Voorbeelden worden wel ter inspiratie
                  gebruikt.
                </li>
                <li>
                  Bij het plaatsen van een bestelling gaat u automatisch akkoord met de Algemene
                  Voorwaarden.
                </li>
              </ul>
            </header>
          </section>

        </div>
      </Layout>
    )
  }
}

export default Voorwaarden;
